import React, {Component} from 'react';
import './style';

class Modal extends Component{

  constructor(props){
    super(props);
  }

  render(){
    return(

      <div className="modal fade" id={this.props.modalId} data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">

          <div className="modal-header hidden">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-content">
            
            <div className="modal-body">
              {this.props.children}
            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default Modal;
