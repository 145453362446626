import {HttpClient} from './httpClient' 
//import { authHeader } from '../helpers'

// This is the API. The backend root URL can be set from here.
const API = '/api/v1'

//Setting the todos URI
const SERVICES_API = `${API}`

// The CRUD Operations of the Todo Resource.
//const config = { headers: {'Authorization': authHeader(),'Accept': 'application/json', 'Content-Type': 'application/json'} }
const config = {}

//Create
const getEstimate = service => {
    //console.log('ESTE ES el token', authHeader())
    return HttpClient.post(`${SERVICES_API}/estimate`, service, config)
}

const payment = body => {
    //console.log('ESTE ES el token', authHeader())
    return HttpClient.post(`${SERVICES_API}/payment`, body, config)
}

const quote = body => {
    //console.log('ESTE ES el token', authHeader())
    return HttpClient.post(`${SERVICES_API}/quote`, body, config)
}

const validatePhone = body => {
    return HttpClient.post(`${SERVICES_API}/validate_phone`, body, config)
}

const validateCode = body => {
    return HttpClient.post(`${SERVICES_API}/validate_code`, body, config)
}

const order = body => {
    //console.log('ESTE ES el token', authHeader())
    return HttpClient.post(`${SERVICES_API}/order`, body, config)
}

//Encapsulating in a JSON object

// const TodoApi = {createTodo, getTodo, updateTodo, removeTodo}
const ServiceEndpoints = { getEstimate, payment, quote, validatePhone, validateCode, order }
export {ServiceEndpoints}