import React, { Component } from 'react'
import { connect } from 'react-redux'

import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'

export class Step4 extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.currentStep !== 4) { // Prop: The current step
        return null
    }
    
    let content = ""
    if(this.props.email === undefined){
        content = <ReactPhoneInput countryCodeEditable={false} onlyCountries={['us']} inputExtraProps={{name: 'phone'}} defaultCountry={'us'}  inputClass="form-control" onChange={this.props.handleChange} value={this.props.phone} />
    }
    else{
        content = <input onChange={this.props.handleChange} value={this.props.email} type="text" className="form-control" name="email" placeholder="jhon@gmail.com"/>
    }

    return (
    <div>
        <div className="form-group">
          {content}
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Step4)
