// app/javascript/quotes/components/App.jsx
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Dashboard from './dashboard/components/Dashboard';

class App extends Component{

  render(){
    return(
      <Router>
        <div>
          <Route exact path='/' render={()=><Dashboard/>} />
        </div>

      </Router>
    );
  }
}

export default App;
