import { ServiceEndpoints } from "../../api/serviceEndpoints";
import { serviceTypes } from '../types'

export const getEstimate = (service) => {
  return (dispatch) => {
      dispatch(getEstimateRequest())
      ServiceEndpoints.getEstimate(service).then(res => {
          let data = res.data;
          return dispatch(getEstimateSuccess(res));
      }).catch((e) => {
        return dispatch(getEstimateFailure(e));
      })
  }
}

const getEstimateRequest = () => { return { type: serviceTypes.GET_ESTIMATE_REQUEST }}
const getEstimateSuccess = (response) => { return { type: serviceTypes.GET_ESTIMATE_SUCCESS, response } }
const getEstimateFailure = (errorMessage)=> { return { type: serviceTypes.GET_ESTIMATE_FAILURE, errorMessage } }


export const payment = (body) => {
  return (dispatch) => {
      dispatch(paymentRequest())
      ServiceEndpoints.payment(body).then(res => {
          return dispatch(paymentSuccess(res));
      }).catch((e) => {
        console.log("Payment Error: ", e);
        return dispatch(paymentFailure(e));
      })
  }
}

const paymentRequest = () => { return { type: serviceTypes.PAYMENT_REQUEST }}
const paymentSuccess = (response) => { return { type: serviceTypes.PAYMENT_SUCCESS, response } }
const paymentFailure = (errorMessage)=> { return { type: serviceTypes.PAYMENT_FAILURE, errorMessage } }


export const quote = (body) => {
  return (dispatch) => {
      dispatch(quoteRequest())
      ServiceEndpoints.quote(body).then(res => {
          console.log("Quote Success: ", res);
          return dispatch(quoteSuccess(res));
      }).catch((e) => {
        console.log("Quote Error: ", e);
        return dispatch(quoteFailure(e));
      })
  }
}

const quoteRequest = () => { return { type: serviceTypes.QUOTE_REQUEST }}
const quoteSuccess = (response) => { return { type: serviceTypes.QUOTE_SUCCESS, response } }
const quoteFailure = (errorMessage)=> { return { type: serviceTypes.QUOTE_FAILURE, errorMessage } }


export const validatePhone = (body) => {
  return (dispatch) => {
      dispatch(validatePhoneRequest())
      ServiceEndpoints.validatePhone(body).then(res => {
          console.log("phone Success: ", res);
          return dispatch(validatePhoneSuccess(res));
      }).catch((e) => {
        console.log("Phone Error: ", e);
        return dispatch(validatePhoneFailure(e));
      })
  }
}

const validatePhoneRequest = () => { return { type: serviceTypes.VALIDATE_PHONE_REQUEST }}
const validatePhoneSuccess = (response) => { return { type: serviceTypes.VALIDATE_PHONE_SUCCESS, response } }
const validatePhoneFailure = (errorMessage)=> { return { type: serviceTypes.VALIDATE_PHONE_FAILURE, errorMessage } }


export const validateCode = (body) => {
  return (dispatch) => {
      dispatch(validateCodeRequest())
      ServiceEndpoints.validateCode(body).then(res => {
          console.log("code Success: ", res);
          return dispatch(validateCodeSuccess(res));
      }).catch((e) => {
        console.log("code Error: ", e);
        return dispatch(validateCodeFailure(e));
      })
  }
}

const validateCodeRequest = () => { return { type: serviceTypes.VALIDATE_CODE_REQUEST }}
const validateCodeSuccess = (response) => { return { type: serviceTypes.VALIDATE_CODE_SUCCESS, response } }
const validateCodeFailure = (errorMessage)=> { return { type: serviceTypes.VALIDATE_CODE_FAILURE, errorMessage } }

export const order = (body) => {
  return (dispatch) => {
      dispatch(orderRequest())
      ServiceEndpoints.order(body).then(res => {
          console.log("Order Success: ", res);
          return dispatch(orderSuccess(res));
      }).catch((e) => {
        console.log("Order Error: ", e);
        return dispatch(orderFailure(e));
      })
  }
}

const orderRequest = () => { return { type: serviceTypes.ORDER_REQUEST }}
const orderSuccess = (response) => { return { type: serviceTypes.ORDER_SUCCESS, response } }
const orderFailure = (errorMessage)=> { return { type: serviceTypes.ORDER_FAILURE, errorMessage } }
