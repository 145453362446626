import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export class Step6 extends Component {
  
  render() {

   
    if (this.props.currentStep !== 6) { // Prop: The current step
      return null
    }

    return (
    <div className="text-center">
        <DatePicker dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={this.props.requestDate}
                        onChange={this.props.handleChange} />
    </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step6);
