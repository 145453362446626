import React, { Component } from 'react'
import { connect } from 'react-redux'
// import './style';

export class Step1 extends Component {
 
  handleOnClick = (event) => {
    let input = event.target.children[0];

    // let e = new Event('input', { bubbles: true });
    // input.dispatchEvent(e);

    // console.log(input.value)
    // console.log(e)


    // var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    // nativeInputValueSetter.call(input, 'react 16 value');

    // var ev2 = new Event('input', { bubbles: true});
    // input.dispatchEvent(ev2);

    // console.log(ev2)



    if(event.target.id == "one"){
      // var event = new MouseEvent('change', {
      //   'view': window, 
      //   'bubbles': true, 
      //   'cancelable': false
      //  });
      //  var node = document.getElementById('1');
      //  console.log(event)
      //  console.log(node)
      //  node.dispatchEvent(event);
    }  

    // }
    // if(event.target.id == "two"){
    //   $("#2").click();
    // }
    // if(event.target.id == "three"){
    //   $("#3").click();
    // }
    // if(event.target.id == "four"){
    //   $("#4").click();
    // }
    // console.log(this.props)
  }

  render() {
    if (this.props.currentStep !== 1) { // Prop: The current step
      return null
    }

    // console.log('esto es : ', this.props.size)
    return (
      <div>
        <div className="text-center"  >

          <label className={"btn btn-circle btn-lg btn-default " + (this.props.size === "500_2000" ? 'active' : '') }  onClick={this.handleOnClick}>
            <input type="radio" 
                   name="size"
                   className="hidden"
                   checked={this.props.size === "500_2000"}
                   onChange={this.props.handleChange}
                   value="500_2000"/> 500 - 2000 sq ft
          </label>
          <br></br>

          <br></br>

          <label className={"btn btn-circle btn-lg btn-default " + (this.props.size === "2001_3000" ? 'active' : '') }  onClick={this.handleOnClick}>
            <input type="radio"
                  name="size"
                  className="hidden"
                   checked={this.props.size === "2001_3000"}
                   onChange={this.props.handleChange}
                   value="2001_3000"/> 2001 - 3000 sq ft


          </label>
          <br></br>



          <br></br>
          <label className={"btn btn-circle btn-lg btn-default " + (this.props.size === "3001_4000" ? 'active' : '') }  onClick={this.handleOnClick}>
            <input type="radio"
                  name="size"
                  className="hidden"
                   checked={this.props.size === "3001_4000"}
                   onChange={this.props.handleChange}
                   value="3001_4000"/> 3001 - 4000 sq ft
                               

          </label>
          <br></br>


          <br></br>
          <label className={"btn btn-circle btn-lg btn-default " + (this.props.size === "4000" ? 'active' : '') }  onClick={this.handleOnClick}>
            <input type="radio"
                  name="size"
                  className="hidden"
                   checked={this.props.size === "4000"}
                   onChange={this.props.handleChange}
                   value="4000"/> > 4000 sq ft
                                
          </label>
          <br></br>



        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1)
