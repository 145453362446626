import { serviceTypes } from '../types'


const initialStateServices = {
  estimage: 0,
  loading: false,
  paymentSuccess: false,
  quoteSuccess: false,
  phoneSuccess: false,
  codeSuccess: false,
  orderSuccess: false,
  customEmail: '',
  errorMessage: '',
}

export const ServiceReducer = (state = initialStateServices, action) => {
    switch(action.type){

      case serviceTypes.GET_ESTIMATE_REQUEST:
        return {
          ...state,
          loading: true
        }

      case serviceTypes.GET_ESTIMATE_SUCCESS:
        return{
          ...state,
          loading: false,
          estimate: action.response.data.total
        }
      case serviceTypes.GET_ESTIMATE_FAILURE:
        return{
          ...state,
          loading: false,
          errorMessage: action.errorMessage
        }
      
      case serviceTypes.PAYMENT_REQUEST:
        return {
          ...state,
          loading: true
        }
      case serviceTypes.PAYMENT_SUCCESS:

        return{
          ...state,
          loading: false,
          paymentSuccess: true,
          customEmail: action.response.data.result.billing_details.name
        }
      case serviceTypes.PAYMENT_FAILURE:
        return{
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
          paymentSuccess: false

        }
      
      case serviceTypes.QUOTE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case serviceTypes.QUOTE_SUCCESS:
        return{
          ...state,
          loading: false,
          quoteSuccess: true
        }
      case serviceTypes.QUOTE_FAILURE:
        return{
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
          quoteSuccess: false
        }
      
      case serviceTypes.VALIDATE_PHONE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case serviceTypes.VALIDATE_PHONE_SUCCESS:
        return{
          ...state,
          loading: false,
          phoneSuccess: true
        }
      case serviceTypes.VALIDATE_PHONE_FAILURE:
        return{
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
          phoneSuccess: false
        }  

      case serviceTypes.VALIDATE_CODE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case serviceTypes.VALIDATE_CODE_SUCCESS:
        return{
          ...state,
          loading: false,
          codeSuccess: true
        }
      case serviceTypes.VALIDATE_CODE_FAILURE:
        return{
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
          codeSuccess: false
        }    
      
      case serviceTypes.ORDER_REQUEST:
        return {
          ...state,
          loading: true
        }
      case serviceTypes.ORDER_SUCCESS:
        return{
          ...state,
          loading: false,
          orderSuccess: true
        }
      case serviceTypes.ORDER_FAILURE:
        return{
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
          orderSuccess: false
        }

      default: 
        return state; 
    }
}