import React, { Component } from 'react';
import Modal from '../../modal/components/Modal'

import GutterForm from '../../gutter/containers/MasterForm'

class Dashboard extends Component{
  
  constructor(){
    super();
    
    this.closeModal = this.closeModal.bind(this);
  }


  componentDidMount(){
    
  }

  closeModal(){
    $('.close').click();
  }

  render(){

    return(
      <div className="our-services">
        <div className="container">
          <h1 className="heading">CERTIFICATIONS</h1>
          <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
          <div className="ser-block hover-style">
                <a href="/services/certifications">
                  <img src="./assets/SBA8-logo.png" height="200"/>
                  <span className="inner-title"></span>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 ">
              <div className="ser-block hover-style">
                <a href="/services/certifications">
                  <img src="./assets/WOSB_Certified.png" height="200"/>
                  <span className="inner-title"></span>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 ">
              <div className="ser-block  hover-style">
                <a href="/services/certifications">
                  <img src="./assets/hubzone.png" height="200"/>
                  <span className="inner-title"></span>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 ">
              <div className="ser-block  hover-style">
                <a href="/services/certifications">
                  <img src="./assets/MBE_Certified.png" height="200"/>
                  <span className="inner-title"></span>
                </a>
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block ser-pressure hover-style">
                <a href="/services/Solar_Roof">
                  <img src="./assets/Solar_Roof.jpg" height="200"/>
                  <span className="inner-title">Solar Roofing</span>
                </a>
              </div>
            </div> */}
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block  hover-style">
                <a href="/services/certifications">
                  <img src="./assets/WBE_Certified.png" height="200"/>
                  <span className="inner-title"></span>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block  hover-style">
                <a href="/services/certifications">
                  <img src="./assets/DBE-Certified.png" height="200"/>
                  <span className="inner-title"></span>
                </a>
              </div>
            </div>
            
            {/* <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block ser-home hover-style">
              <a class="exterior-door" data-target="#myModal" data-toggle="modal" href="javascript:void(0)">
                  <img src="./assets/doors.jpg" height="200"/>
                  <span className="inner-title">Exterior Doors</span>
                </a>
              </div>
            </div> */}
            <div>
              
            {/* <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="ser-block ser-solar hover-style">
              <a class="gutter-installation" data-target="#myModal" data-toggle="modal" href="javascript:void(0)">
                  <img src="./assets/GutterInstallation_Image.jpg" height="200"/>
                  <span className="inner-title">Gutters</span>
                </a>
              </div>
            </div> */}

            <Modal closeModal={this.closeModal} modalId="edit" title='Select house size' action="edit">
              <GutterForm closeModal={this.closeModal} />
            </Modal>
                  
          </div>
          </div>
        </div>
      </div> 
    );
  }

}

export default Dashboard;
