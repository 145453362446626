import React, { Component } from 'react'
import { connect } from 'react-redux'
import Step1 from '../components/Step1'
import Step2 from '../components/Step2'
import Step3 from '../components/Step3'
import Step4 from '../components/Step4'
import Step5 from '../components/Step5'
import Step6 from '../components/Step6'
import Step7 from '../components/Step7'

import * as serviceActions from '../../store/actions/serviceActions'
import { bindActionCreators } from 'redux'

export class MasterForm extends Component {

  constructor(props) {
    super(props)
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      title: 'Select House size',
      size: '',
      stories: '',
      address: '',
      state: 'MA',
      city: '',
      zip: '',
      phone: '',
      code: '',
      email: '',
      requestDate: '',
      enabler: false,
      phoneSuccess: false,
      codeSuccess: false
    }
    // Bind the submission to handleChange() 
    this.handleChange = this.handleChange.bind(this)
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.changeTitle = this.changeTitle.bind(this)
    this.nextPhone = this.nextPhone.bind(this)
    this.nextCode = this.nextCode.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.sendQuote = this.sendQuote.bind(this)
    this.closeModal = this.closeModal.bind(this)

  }

  closeModal = () => {
    window.location.reload();
  }

  sendQuote = () => {
    this.props.actions.quote({ "address": this.state.address, "email": this.state.email, "service": { "service_name": "gutter", "service_type": "Cleaning", "house_size": this.state.size, "floors": this.state.stories } })
    $('.close').click();
  }

  handleChange(event) {
    if (this.state.currentStep == 3) {
      const { name, value } = event.target
      this.setState({
        [name]: value
      })
      if (this.state.address.trim() != '' && this.state.zip.trim() != '' && this.state.city.trim() != '') {
        this.setState({
          enabler: true
        })
      }
    } else {
      const { name, value } = event.target
      this.setState({
        [name]: value,
        enabler: true
      })
    }
  }

  handleChangePhone(value, data) {
    this.setState({
      phone: value.replace(/[^0-9]+/g, '').slice(data.dialCode.length),
      enabler: true
    })
  }

  handleChangeDate(date) {
    this.setState({
      requestDate: date,
      enabler: true
    });
  }

  changeTitle(currentStep) {
    let title = this.state.title
    if (currentStep == 1) {
      title = 'Select House size'
    }
    if (currentStep == 2) {
      title = 'Select number of stories'
    }
    if (currentStep == 3) {
      title = 'Where would you like your gutter cleaning?'
    }
    if (currentStep == 4) {
      if (currentStep === 4 && (this.state.size === "4000" || this.state.stories === "3")) {
        title = 'We will send you the gutter cleaning estimate. What is your email address?'
      }
      else {
        title = 'What’s your phone number?'
      }
    }
    if (currentStep == 5) {
      title = 'What is the code your received in your phone?'
    }

    if (currentStep == 6) {
      title = 'Request a date for service'
    }

    if (currentStep == 7) {
      title = ''
    }
    return title
  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  // Test current step with ternary
  // next and _previous functions will be called on button click
  next() {
    let currentStep = this.state.currentStep
    currentStep = currentStep >= 6 ? 7 : currentStep + 1
    this.setState({
      currentStep: currentStep,
      title: this.changeTitle(currentStep),
      enabler: false
    })
  }

  nextPhone() {
    let body = {};
    body['phone'] = this.state.phone;
    this.props.actions.validatePhone(body)
  }

  nextCode() {
    let body = {};
    body['code'] = this.state.code;
    body['phone'] = this.state.phone;
    this.props.actions.validateCode(body)
  }

  prev() {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    if (currentStep == 2) {
      this.setState({
        size: '',
        stories: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        phone: '',
        code: '',
        email: '',
        requestDate: ''
      })
    }
    else if (currentStep == 3) {
      this.setState({
        stories: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        phone: '',
        code: '',
        email: '',
        requestDate: ''
      })
    }
    else if (currentStep == 4) {
      this.setState({
        address: '',
        state: '',
        city: '',
        zip: '',
        phone: '',
        code: '',
        email: '',
        requestDate: ''
      })
    }
    else if (currentStep == 5) {
      this.setState({
        phone: '',
        code: '',
        email: '',
        requestDate: ''
      })
    }
    else if (currentStep == 6) {
      this.setState({
        code: '',
        email: '',
        requestDate: ''
      })
    }
    else if (currentStep == 7) {
      this.setState({
        requestDate: ''
      })
    }
    this.setState({
      currentStep: currentStep,
      title: this.changeTitle(currentStep),
      enabler: false,
    })
  }

  get previousButton() {
    let currentStep = this.state.currentStep;

    if (currentStep <= 7) {
      if (currentStep === 1) {
        return (
          <button
            className="btn-link"
            type="button" onClick={this.closeModal}>
            Back
          </button>
        )
      }
      else {
        return (
          <button
            className="btn-link"
            type="button" onClick={this.prev}>
            Back
          </button>
        )
      }

    }
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 7) {
      if (currentStep == 4 && !(this.state.size === "4000" || this.state.stories === "3")) {
        return (
          <button
            disabled={!this.state.enabler}
            className="btn-sml float-left"
            type="button" onClick={this.nextPhone}>
            Next
          </button>
        )
      }
      else if (currentStep == 5) {
        return (
          <button
            disabled={!this.state.enabler}
            className="btn-sml float-left"
            type="button" onClick={this.nextCode}>
            Next
          </button>
        )
      }
      if (currentStep === 4 && (this.state.size === "4000" || this.state.stories === "3")) {
        return (
          <button
            disabled={!this.state.enabler}
            className="btn-sml float-left"
            type="button" onClick={this.sendQuote}>
            Finish
          </button>
        )
      }
      else {
        return (
          <button
            disabled={!this.state.enabler}
            className="btn-sml float-left"
            type="button" onClick={this.next}>
            Next
          </button>
        )
      }

    }

    this.props.actions.getEstimate({ "service_name": "gutter", "service_type": "Cleaning", "house_size": this.state.size, "floors": this.state.stories })
    return null;
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.phoneSuccess == true && this.state.phoneSuccess == false) {
      let currentStep = this.state.currentStep
      currentStep = currentStep >= 5 ? 6 : currentStep + 1
      this.setState({
        currentStep: currentStep,
        title: this.changeTitle(currentStep),
        enabler: false,
        phoneSuccess: true
      })
    }

    if (nextProps.codeSuccess == true && this.state.codeSuccess == false) {
      let currentStep = this.state.currentStep
      currentStep = currentStep >= 5 ? 6 : currentStep + 1
      this.setState({
        currentStep: currentStep,
        title: this.changeTitle(currentStep),
        enabler: false,
        codeSuccess: true
      })
    }
  }

  render() {

    let content = ""
    if (this.state.currentStep === 4 && (this.state.size === "4000" || this.state.stories === "3")) {
      content = <Step4
        currentStep={this.state.currentStep}
        handleChange={this.handleChange}
        email={this.state.email}
      />
    }
    else {
      content = <Step4
        currentStep={this.state.currentStep}
        handleChange={this.handleChangePhone}
        phone={this.state.phone}
      />
    }


    return (
      <div>

        {this.previousButton}

        <h1 className="heading-title"> {this.state.title} </h1>

        <br></br>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8 text-center">

              <Step1
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                size={this.state.size}
              />

              <Step2
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                stories={this.state.stories}
              />

              <Step3
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                address={this.state.address}
                state={this.state.state}
                city={this.state.city}
                zip={this.state.zip}
              />

              {content}

              <Step5
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                code={this.state.code}
              />

              <Step6
                currentStep={this.state.currentStep}
                handleChange={this.handleChangeDate}
                requestDate={this.state.requestDate}
              />

              <Step7
                currentStep={this.state.currentStep}
                estimate={this.props.estimate}
                address={this.state.address}
                state={this.state.state}
                city={this.state.city}
                zip={this.state.zip}
                size={this.state.size}
                stories={this.state.stories}
                phone={this.state.phone}
                requestDate={this.state.requestDate}
              />

              <br /><br />



              {this.nextButton}
            </div>
            <div className="col-md-2" />

          </div>



        </form>

      </div>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
    estimate: state.ServiceReducer.estimate,
    phoneSuccess: state.ServiceReducer.phoneSuccess,
    codeSuccess: state.ServiceReducer.codeSuccess
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(serviceActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterForm);
