import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Step2 extends Component {
  

  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
        return null
      }
  
    return (
      <div>
      <div className="text-center"  >

        <label className={"btn btn-circle btn-lg btn-default " + (this.props.stories === "1" ? 'active' : '') }  onClick={this.handleOnClick}>
          <input type="radio" 
                 name="stories"
                 className="hidden"
                 checked={this.props.stories === "1"}
                 onChange={this.props.handleChange}
                 value="1"/> 1
        </label>
        <br></br>

        <br></br>

        <label className={"btn btn-circle btn-lg btn-default " + (this.props.stories === "2" ? 'active' : '') }  onClick={this.handleOnClick}>
          <input type="radio"
                name="stories"
                className="hidden"
                 checked={this.props.stories === "2"}
                 onChange={this.props.handleChange}
                 value="2"/> 2


        </label>
        <br></br>



        <br></br>
        <label className={"btn btn-circle btn-lg btn-default " + (this.props.stories === "3" ? 'active' : '') }  onClick={this.handleOnClick}>
          <input type="radio"
                name="stories"
                className="hidden"
                 checked={this.props.stories === "3"}
                 onChange={this.props.handleChange}
                 value="3"/> 3
                             

        </label>
        <br></br>



      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2)
