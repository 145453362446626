export const serviceTypes = {
    GET_ESTIMATE_REQUEST: 'GET_ESTIMATE_REQUEST', 
    GET_ESTIMATE_SUCCESS: 'GET_ESTIMATE_SUCCESS', 
    GET_ESTIMATE_FAILURE: 'GET_ESTIMATE_FAILURE' ,

    PAYMENT_REQUEST: 'PAYMENT_REQUEST', 
    PAYMENT_SUCCESS: 'PAYMENT_SUCCESS', 
    PAYMENT_FAILURE: 'PAYMENT_FAILURE',

    QUOTE_REQUEST: 'QUOTE_REQUEST', 
    QUOTE_SUCCESS: 'QUOTE_SUCCESS', 
    QUOTE_FAILURE: 'QUOTE_FAILURE',

    VALIDATE_PHONE_REQUEST: 'VALIDATE_PHONE_REQUEST', 
    VALIDATE_PHONE_SUCCESS: 'VALIDATE_PHONE_SUCCESS', 
    VALIDATE_PHONE_FAILURE: 'VALIDATE_PHONE_FAILURE',

    VALIDATE_CODE_REQUEST: 'VALIDATE_CODE_REQUEST', 
    VALIDATE_CODE_SUCCESS: 'VALIDATE_CODE_SUCCESS', 
    VALIDATE_CODE_FAILURE: 'VALIDATE_CODE_FAILURE',

    ORDER_REQUEST: 'ORDER_REQUEST', 
    ORDER_SUCCESS: 'ORDER_SUCCESS', 
    ORDER_FAILURE: 'ORDER_FAILURE',

};