import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Step5 extends Component {

  render() {
    if (this.props.currentStep !== 5) { // Prop: The current step
        return null
    }

    return (
    <div>
        <div className="form-group">
            <input onChange={this.props.handleChange} value={this.props.code} type="text" className="form-control" name="code" placeholder="1234"/>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Step5)
