import React, { Component } from 'react'
import { connect } from 'react-redux'
import StripeCheckout from 'react-stripe-checkout';
import * as serviceActions from '../../store/actions/serviceActions'
import { bindActionCreators } from 'redux'

export class Step7 extends Component {
  
  constructor(props) {
    super(props)
    // Set the initial input values
    this.state = {
      email: 'juanito1@gmail.com'
    }
  }

  onToken = (token, addresses) => {
    const body = {
      amount: this.props.estimate*100,
      token: token
    };
    this.props.actions.payment(body)
  };

  componentDidUpdate(prevProps){
    console.log(this.props)
    if(this.props.paymentSuccess){
      console.log('se tendria que ejecutar el order')
      let order = {
        "user": 
        {
          "email": this.props.customEmail,
          "zip": this.props.zip,
          "phone": this.props.phone,
          "state": this.props.state,
          "city": this.props.city,
          "address": this.props.address
        },
        "order":
        {
          "order_status": "Pending",
          "order_date": this.props.requestDate,
          "payment_status": "paid",
          "address": this.props.address
          
        },
        "service": 
          {
            "service_name": "gutter",
            "service_type": "Cleaning",
            "house_size": this.props.size,
            "floors": this.props.stories
          },
          "quote": {
            "price": this.props.estimate
          }
        
      }
      this.props.actions.order(order)
    }
  }

  render() {

    if (this.props.currentStep !== 7) {
        return null
    }
    if(this.props.paymentSuccess){
      alert('Thank you for placing your order.')
      window.location.reload();
    }

    return (
    <div className="text-center">
       <h1>Total Price: ${this.props.estimate} USD</h1> 
       <br></br>
       <StripeCheckout
          amount={this.props.estimate*100}
          // description="Formerly Hector Power Cleaning"
          name="Emmaty Inc"
          stripeKey="pk_live_WbjAikon8hQdEPwUZh6iDmoF"
          token={this.onToken}
          label="Pay with 💳"
        >
        <button className="btn-sml float-right">
          Pay with 💳
        </button>
      </StripeCheckout>
    </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    paymentSuccess: state.ServiceReducer.paymentSuccess,
    orderSuccess: state.ServiceReducer.orderSuccess,
    customEmail: state.ServiceReducer.customEmail
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(serviceActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step7);
