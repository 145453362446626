import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Step3 extends Component {
  

  render() {
    if (this.props.currentStep !== 3) { // Prop: The current step
        return null
    }

    return (
    <div>
        <div className="form-group">
          <input type="text" className= "form-control" name='address' onChange={this.props.handleChange} value={this.props.address} rows = "1" placeholder = "Address"/>
          <br></br>
          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" onChange={this.props.handleChange} value={this.props.city} name="city" placeholder="city"/>
            </div>
            <div className="col-md-3">
              <select className="form-control" value={this.props.state} name="state" onChange={this.props.handleChange}>
                <option value="MA">MA</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="DC">DC</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
            </div>
            <div className="col-md-3">
              <input type="text" className="form-control" onChange={this.props.handleChange} value={this.props.zip} name="zip" placeholder="zip"/>
            </div>

          </div>

        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3)
